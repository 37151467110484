import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "auto",
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
  })
);

export default function SelectComponent({
  name,
  label,
  setFieldValue,
  options,
  defValue,
  setFieldTouched,
  multiple,
}) {
  const classes = useStyles();

  const handleChange = (event, value) => {
    let values;
    if (multiple) values = value?.join(",");
    else values = value;
    setFieldValue(name, values);
  };
  return (
    <div className={classes.root}>
      <Typography
        style={{
          fontFamily: "Muli",
          fontSize: "14px",
          textAlign: "left",
          color: "#212121",
        }}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple={multiple}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name)}
        options={options}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`Select ${label}`}
          />
        )}
        defaultValue={multiple ? defValue?.split(",") : defValue}
      />
    </div>
  );
}
