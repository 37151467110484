import React, { useEffect, useState, useMemo } from "react";
import Avatar from "@material-ui/core/Avatar";
import soundService from "../services/sound-service";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import { useSnackbar } from "notistack";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import axios from "../http-common";
import { saveAs } from "file-saver";
import AllSoundsTable from "../components/AllSoundsTable";
import { index } from "../config";
import debounce from "lodash.debounce";
import { formatSearchData } from "../services/format-search-data";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";

export default function AllSounds() {
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [controlledPageCount, setControlledPageCount] = React.useState(0);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const rowsPerPage = 10;
  // Column of react table
  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        Cell: (props) => {
          const { value, cell } = props;
          return <Avatar alt={cell.row.original.title} src={value} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Artist",
        accessor: "artist",
      },
      {
        Header: "Tags",
        accessor: "tags",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Created By",
        accessor: "createdBy",
      },
      {
        Header: "Download",
        accessor: "download",
        Cell: (props) => {
          const { cell } = props;
          return (
            <GetAppIcon
              {...cell.getCellProps()}
              className={`is-${cell.column.id}`}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleLyricsDownload(
                  cell.row.original.lyrics,
                  cell.row.original.title
                )
              }
            />
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (props) => {
          const { cell } = props;
          const editClicked = () => {
            history.push({
              pathname: "/update",
              state: {
                ...cell.row.original,
              },
            });
          };
          return (
            <>
              <EditIcon
                onClick={editClicked}
                style={{ marginRight: "5px", cursor: "pointer" }}
              />
              <DeleteForeverIcon
                onClick={() => handleDeleteSong(cell.row.original.id)}
                style={{ cursor: "pointer" }}
              />
            </>
          );
        },
      },
    ],
    []
  );

  const handleSearch = async (e) => {
    setSearchQuery(e.target.value);
    const response = await (
      await index
    ).search(e.target.value, { limit: rowsPerPage, sort: ["date:desc"] });
    if (response) {
      setControlledPageCount(
        ~~((response.nbHits + rowsPerPage - 1) / rowsPerPage)
      );
      setRowData(formatSearchData(response));
    }
  };
  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 500), []);

  useEffect(() => {
    (async function anonymousFunction() {
      // const allSoundData = await soundService.getAllSounds();
      const data = await (
        await index
      ).search("", { limit: rowsPerPage, sort: ["date:desc"] });
      if (data) {
        setControlledPageCount(
          ~~((data.nbHits + rowsPerPage - 1) / rowsPerPage)
        );
        setRowData(formatSearchData(data));
      }
    })();
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);

  const handleDeleteSong = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this song?"
    );
    if (confirmBox === true) {
      soundService.deleteSound(id, history, enqueueSnackbar);
    }
  };
  const handleLyricsDownload = (url, title) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        var blob = new Blob([response.data]);
        saveAs(blob, `${title}.txt`);
      })
      .catch((err) => console.log(err));
  };

  return (
    <MainLayout>
      <div
        className="ag-theme-alpine"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <TextField
              style={{ marginBottom: "10px" }}
              id="input-with-icon-text"
              label="Search"
              variant="outlined"
              onChange={(e) => {
                e.persist();
                debouncedHandleSearch(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <AllSoundsTable
            searchQuery={searchQuery}
            columns={columns}
            rowData={rowData}
            setRowData={setRowData}
            controlledPageCount={controlledPageCount}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </div>
    </MainLayout>
  );
}
