import React, { useEffect, useState } from "react";
import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    color: "white",
    marginRight: theme.spacing(2),
  },
  menuActive: {
    color: "black",
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Link
            className={`${classes.menuButton} ${
              history.location.pathname === "/upload"
                ? classes.menuActive
                : null
            }`}
            to="/upload"
          >
            Upload
          </Link>
          <Link
            className={`${classes.menuButton} ${
              history.location.pathname === "/all-sounds"
                ? classes.menuActive
                : null
            }`}
            to="/all-sounds"
          >
            All Sounds
          </Link>
          <Link
            className={`${classes.menuButton} ${
              history.location.pathname === "/report"
                ? classes.menuActive
                : null
            }`}
            to="/report"
          >
            Reports
          </Link>
          <div style={{ marginLeft: "auto" }}>
            <button
              onClick={() => {
                localStorage.removeItem("token");
                history.push("/");
              }}
            >
              Logout
            </button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
