import soundService from "./sound-service";

export function formatSearchData(data) {
  const tableData = data.hits.map((item) => {
    return {
      id: item.id,
      image: item.imageUrl,
      artist: item.artist,
      title: item.title,
      lyrics: item.lyrics,
      tags: item.tag.join(", "),
      category: item.category,
      createdBy: item.createdBy,
      date: soundService.timeConverter(item.date),
      movie: item.movie,
      album: item.movie,
      musicDirector: item.musicDirector,
      producer: item.producer,
    };
  });
  return tableData;
}
