import React, { useEffect } from "react";
import { usePagination, useTable } from "react-table";
import { index } from "../config";
import { formatSearchData } from "../services/format-search-data";

function AllSoundsTable({
  columns,
  rowData,
  setRowData,
  controlledPageCount,
  rowsPerPage,
  searchQuery,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: rowData,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );
  useEffect(() => {
    gotoPage(0);
  }, [searchQuery]);
  useEffect(() => {
    (async function anonymousFunction() {
      const data = await (
        await index
      ).search(searchQuery, {
        offset: pageIndex * pageSize,
        limit: rowsPerPage,
        sort: ["date:desc"],
      });
      if (data) {
        setRowData(formatSearchData(data));
      }
    })();
  }, [pageIndex, pageSize]);

  return (
    <>
      <table
        {...getTableProps()}
        style={{ border: "solid 1px blue", width: "100%", overflow: "scroll" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: "papayawhip",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination start */}
      <div
        className="pagination"
        style={{ marginTop: "24px", marginBottom: "100px", width: "100%" }}
      >
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
      </div>
    </>
  );
}

export default AllSoundsTable;
