import React, { useState } from "react";
import {
  Typography,
  TextField,
  Grid,
  InputLabel,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form, Formik } from "formik";
import uploadFilesService from "../services/upload-files.service";
import SelectComponent from "../components/SelectComponent";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../Layout/MainLayout";
import { useSnackbar } from "notistack";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      // padding: "0px 0px 15px 0px",
      marginBottom: "1rem",
      width: "80%",
    },
    error: {
      color: "red",
      marginBottom: ".5rem",
    },
    uploadWarning: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#ea5252",
    },
    uploadActive: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#41c300",
    },
    uploadDisabled: {
      fontWeight: "normal",
      fontSize: "16px",
      color: "#eeeeee",
    },
  })
);

const FormSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required"),
  artist: Yup.string().required("Artist is Required"),
  tags: Yup.string().required("Tag is Required"),
  category: Yup.string().required("Category is Required"),
});

function Update(props) {
  const classes = useStyles();

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    artist,
    category,
    createdBy,
    date,
    id,
    image,
    tags,
    title,
    movie,
    album,
    musicDirector,
    producer,
  } = props.location.state;

  const msg = localStorage.getItem("message");
  const [message, setMessage] = useState(msg);
  console.log(props.location.state);
  const handleMessageClose = () => {
    setMessage("");
    localStorage.removeItem("message");
  };
  React.useEffect(() => {
    localStorage.removeItem("message");
  }, []);
  return (
    <MainLayout>
      <div className="container">
        <div className="mg20">
          <Typography variant="h5">Update Music</Typography>
        </div>
        <Formik
          initialValues={{
            title: title,
            artist: artist,
            tags: tags,
            category: category,
            image: image,
            movie: movie,
            album: album,
            music_director: musicDirector,
            producer: producer,
            id: id,
          }}
          validationSchema={FormSchema}
          validateOnMount
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // same shape as initial values
            setTimeout(() => {
              const formData = {};
              formData.title = values.title.trim();
              formData.tags = values.tags.trim();
              formData.artist = values.artist.trim();
              formData.id = values.id;
              if (values.movie) formData.movie = values.movie.trim();
              if (values.album) formData.album = values.album.trim();
              if (values.music_director)
                formData.musicDirector = values.music_director.trim();
              if (values.producer) formData.producer = values.producer.trim();
              if (values.category) formData.category = values.category.trim();
              uploadFilesService.updateSongData(
                formData,
                setSubmitting,
                enqueueSnackbar,
                history
              );
            }, 400);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            isValid,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
          }) => (
            <Form>
              {/* {console.log(values, errors, touched)} */}
              <Grid container>
                <Grid item xs={12}>
                  {/* <UploadFiles
                  setFile={setMusic}
                  name="music"
                /> */}
                  <Avatar
                    alt="Song"
                    src={image}
                    style={{ marginBottom: "15px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="title">Title</InputLabel>
                  <TextField
                    className={classes.input}
                    id="title"
                    name="title"
                    value={values.title}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("title")}
                  />
                  {errors.title && touched.title ? (
                    <div className={classes.error}>{errors.title}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="artist">Artist</InputLabel>
                  <TextField
                    className={classes.input}
                    name="artist"
                    value={values.artist}
                    id="artist"
                    onChange={(e) => {
                      setFieldValue("artist", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("artist")}
                  />
                  {errors.artist && touched.artist ? (
                    <div className={classes.error}>{errors.artist}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="movie">Movie (optional)</InputLabel>
                  <TextField
                    className={classes.input}
                    name="movie"
                    value={values.movie}
                    id="movie"
                    onChange={(e) => {
                      setFieldValue("movie", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("movie")}
                  />
                  {errors.movie && touched.movie ? (
                    <div className={classes.error}>{errors.movie}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="album">Album (optional)</InputLabel>
                  <TextField
                    className={classes.input}
                    name="album"
                    value={values.album}
                    id="album"
                    onChange={(e) => {
                      setFieldValue("album", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("album")}
                  />
                  {errors.album && touched.album ? (
                    <div className={classes.error}>{errors.album}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="music_director">
                    Music Director (optional)
                  </InputLabel>
                  <TextField
                    className={classes.input}
                    name="music_director"
                    value={values.music_director}
                    id="music_director"
                    onChange={(e) => {
                      setFieldValue("music_director", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("music_director")}
                  />
                  {errors.music_director && touched.movie ? (
                    <div className={classes.error}>{errors.music_director}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="producer">
                    Producer (optional)
                  </InputLabel>
                  <TextField
                    className={classes.input}
                    name="producer"
                    value={values.producer}
                    id="producer"
                    onChange={(e) => {
                      setFieldValue("producer", e.target.value);
                    }}
                    onBlur={() => setFieldTouched("producer")}
                  />
                  {errors.producer && touched.movie ? (
                    <div className={classes.error}>{errors.producer}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {/* <InputLabel htmlFor="tags">Tags</InputLabel> */}
                  {/* <TextField
                  className={classes.input}
                  name="tags"
                  id="tags"
                  onChange={handleChange}
                /> */}
                  <SelectComponent
                    name="tags"
                    defValue={values.tags}
                    label="Tags"
                    options={["premium", "new", "recomended"]}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    multiple={true}
                  ></SelectComponent>
                  {errors.tags && touched.tags ? (
                    <div className={classes.error}>{errors.tags}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <SelectComponent
                    name="category"
                    defValue={values.category}
                    label="Category"
                    options={["english", "bangla", "hindi", "sounds"]}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    multiple={true}
                  ></SelectComponent>
                  {errors.category && touched.category ? (
                    <div className={classes.error}>{errors.category}</div>
                  ) : null}
                </Grid>
                <Button
                  style={{ marginTop: "1rem", marginBottom: "10rem" }}
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        width: "25px",
                        height: "25px",
                        marginLeft: "10px",
                      }}
                      color="secondary"
                    />
                  )}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
        {message && (
          <Alert severity="success">
            {message}{" "}
            <span
              style={{ textDecoration: "none" }}
              onClick={handleMessageClose}
            >
              close
            </span>
          </Alert>
        )}
      </div>
    </MainLayout>
  );
}

export default Update;
