import React, { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import axios from "../http-common";
import { saveAs } from "file-saver";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import { format } from "date-fns";

export default function Reports() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handlePdfDownload = () => {
    const startDate = format(state[0].startDate, "yyyy-MM-dd");
    const endDate = format(state[0].endDate, "yyyy-MM-dd");
    axios
      .get(`/pdf?startDate=${startDate}&endDate=${endDate}`, {
        responseType: "blob",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8",
        });
        saveAs(
          blob,
          `Pdf_Report_${new Date().toLocaleDateString("en-US")}.pdf`
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <MainLayout>
      <div
        className="ag-theme-alpine"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>
            <DateRange
              editableDateInputs={false}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
            <button style={{ marginLeft: "50px" }} onClick={handlePdfDownload}>
              Download PDF Report
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
