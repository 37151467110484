import * as React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ Component, path, exact = false }) => {
  const isAuthenticated = localStorage.getItem("token");

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: "Unauthorized !",
                },
              }}
            />
          );
        }
      }}
    />
  );
};
export default PrivateRoute;
