import http from "../http-common";
import { baseUrl } from "./../config";

class Auth {
  login(data, history, setSubmitting, enqueueSnackbar) {
    http
      .post(`${baseUrl}/admin/login`, data)
      .then((res) => {
        if (res.data.status === 401 || res.data.status === 400) {
          enqueueSnackbar(res.data.message, {
            variant: "error",
          });
        } else {
          localStorage.setItem("token", res.data.data.token);
          window.location.href = "/upload";
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/";
        setSubmitting(false);
      });
  }
}

export default new Auth();
