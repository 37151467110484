import MeiliSearch from "meilisearch";

export const baseUrl = "https://upload.singistic.com";
// export const baseUrl = "https://dev.backend.singistic.com";
// export const S3Config = {
//   bucketName: process.env.REACT_APP_S3_BUCKET,
//   region: process.env.REACT_APP_REGION,
//   accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
//   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
// };
export const S3Config = {
  bucketName: "be-music",
  region: "us-west-1",
  accessKeyId: "AKIAV7T4VGTEFAOZCSG2",
  secretAccessKey: "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",
};

const client = new MeiliSearch({
  host: "https://search.singistic.com/",
});

export const index = client
  .getIndex("Musics")
  .catch((err) => console.log("MeiliSearch Error: ", err));
